.home {
  position: relative;
  margin-bottom: 100px;
}
.home__slideshow {
  overflow: hidden;
  position: relative;
}
.home__slideshow .slick-dots {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  z-index: 900;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}
.home__slideshow .slick-dots > li {
  display: inline-block;
  margin: 0 5px;
  font-size: 0;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #000;
  transition: background 0.3s;
}
.home__slideshow .slick-dots > li.slick-active {
  background: #ffba00;
}
.home__slideshow .slick-dots > li > button {
  outline: none;
}
@media (max-width: 64em) {
  .home__slideshow .slick-dots {
    bottom: 10px;
  }
}
.home__slideshow, .home__slideshow-carousel, .home__slideshow-item, .home__slideshow-item .image {
  width: 100%;
  height: 800px;
  max-height: 70vh;
}
.home__overlay {
  background: rgba(255, 186, 0, 0.8);
  position: absolute;
  top: 0;
  left: -100px;
  width: 100%;
  height: 100%;
  transform: skew(15deg, 0deg) translateX(-50%);
  margin-left: -100vw;
  transition: margin 1s;
}
@media (max-width: 64em) {
  .home__overlay {
    margin-left: 0;
    transition: none;
  }
}
.home__overlay.show {
  margin-left: 0;
}
html[dir=rtl] .home__overlay {
  left: auto;
  right: -100px;
  transform: skew(15deg, 0deg) translateX(50%);
  margin-left: 0;
  margin-right: -100vw;
}
html[dir=rtl] .home__overlay.show {
  margin-right: 0;
}
.home__slogan {
  position: absolute;
  width: 1180px;
  top: 10vh;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 1.25s ease 0.8s;
}
@media (max-width: 64em) {
  .home__slogan {
    transition: none;
    opacity: 1;
  }
}
.home__slogan .inner {
  color: #fff;
  font-weight: normal;
  font-size: 2.8125rem;
  line-height: 55px;
  max-width: 380px;
}
.home__slogan.show {
  opacity: 1;
}
@media screen and (max-width: 1180px) {
  .home__slogan {
    left: 20px;
    transform: none;
    width: calc(100vw - 50px);
  }
  .home__slogan .inner {
    font-size: 2.5rem;
    line-height: 55px;
    max-width: 400px;
  }
}
@media screen and (max-width: 1000px) {
  .home__slogan .inner {
    font-size: 1.875rem;
    line-height: 41.25px;
    max-width: 220px;
  }
}
.home__bullet-points {
  padding: 50px 0 0;
  background: #fff;
}
html[dir=ltr] .home__bullet-points {
  position: relative;
  z-index: 1;
}
html[dir=ltr] .home__bullet-points:before {
  background: inherit;
  content: "";
  display: block;
  min-height: 200px;
  height: 11vw;
  left: 0;
  position: absolute;
  right: 0;
  z-index: -1;
  -webkit-backface-visibility: hidden;
}
html[dir=ltr] .home__bullet-points:before {
  top: -10px;
  transform: skewY(-5.5deg);
  transform-origin: 0 0;
}
html[dir=rtl] .home__bullet-points {
  position: relative;
  z-index: 1;
}
html[dir=rtl] .home__bullet-points:before {
  background: inherit;
  content: "";
  display: block;
  min-height: 200px;
  height: 11vw;
  left: 0;
  position: absolute;
  right: 0;
  z-index: -1;
  -webkit-backface-visibility: hidden;
}
html[dir=rtl] .home__bullet-points:before {
  top: -10px;
  transform: skewY(5.5deg);
  transform-origin: 100% 0;
}
.home__bullet-points .col {
  padding: 0 55px;
  text-align: center;
}
.home__bullet-points .icon {
  height: 106px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home__bullet-points h2 {
  margin-top: 20px;
  margin-bottom: 20px;
  color: #000;
}
.home__bullet-points ul {
  display: inline-block;
  margin: 0 auto;
  text-align: left;
  list-style-position: inside;
}
.home__bullet-points ul li::before {
  background-size: 16px;
  width: 16px;
  height: 16px;
  top: 7px;
  margin: -2px 0 0 6px;
}
.home__about-us, .home__more-projects {
  padding: 80px 0;
  text-align: center;
}
@media (max-width: 48em) {
  .home__about-us, .home__more-projects {
    padding-top: 40px;
  }
}
.home__more-projects {
  padding-bottom: 20px;
}
.home__services {
  text-align: center;
  margin: 0 0 150px;
}
@media (max-width: 48em) {
  .home__services .odd,
  .home__services .even {
    padding: 0;
  }
}
.home__service.odd {
  padding-left: 10px;
}
html[dir=rtl] .home__service.odd {
  padding-right: 10px;
}
.home__service.even {
  padding-right: 10px;
}
html[dir=rtl] .home__service.even {
  padding-left: 10px;
}
.home__service h2 {
  margin-bottom: 15px;
  color: #000;
}
.home__service-imagecontainer {
  position: relative;
  overflow: hidden;
  display: block;
  display: block;
  margin: 0 0 75px;
  text-decoration: none;
}
html.isdesktop .home__service-imagecontainer:hover::after {
  margin-left: 0;
}
html[dir=rtl] .home__service-imagecontainer:hover::after {
  margin-right: 0;
}
html:not(.isdesktop) .home__service-imagecontainer {
  overflow: visible;
  margin-bottom: 30px;
}
.home__service-imagecontainer::after {
  content: "";
  pointer-events: none;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 725px;
  transform: skew(-15deg, 0deg) translateX(-300px);
  margin-left: -100%;
  transition: margin 0.25s;
}
html[dir=rtl] .home__service-imagecontainer::after {
  left: auto;
  right: 0;
  margin-left: 0;
  margin-right: -100%;
  transform: skew(-15deg, 0deg) translateX(300px);
}
html:not(.isdesktop) .home__service-imagecontainer::after {
  display: none;
}
html.isdesktop .home__service-imagecontainer:hover .home__service-checklist {
  opacity: 1;
}
.home__service-checklist {
  position: absolute;
  top: 30px;
  left: 50px;
  color: #fff;
  transform: none;
  text-align: left;
  max-width: 57%;
  opacity: 0;
  transition: opacity 0.3s ease 0.1s;
  z-index: 10;
}
html[dir=rtl] .home__service-checklist {
  left: auto;
  right: 20px;
  text-align: right;
}
html.isdesktop .home__service-checklist {
  padding-left: 0;
}
html:not(.isdesktop) .home__service-checklist {
  position: static;
  color: #000;
  opacity: 1;
  margin-top: 15px;
  padding-left: 22px;
  max-width: initial;
}
html .home__service-checklist li:before {
  background-size: 14px;
  top: 5px;
  left: -21px;
}
.home__service-button {
  margin: 0 0 50px;
}
html.isdesktop .home__service-button {
  display: none;
}
.home__carousel {
  background: #000;
  min-height: 200px;
  color: #fff;
}
html[dir=ltr] .home__carousel {
  position: relative;
  z-index: 1;
}
html[dir=ltr] .home__carousel:before {
  background: inherit;
  content: "";
  display: block;
  min-height: 200px;
  height: 11vw;
  left: 0;
  position: absolute;
  right: 0;
  z-index: -1;
  -webkit-backface-visibility: hidden;
}
html[dir=ltr] .home__carousel:before {
  top: -10px;
  transform: skewY(-5.5deg);
  transform-origin: 0 0;
}
html[dir=rtl] .home__carousel {
  position: relative;
  z-index: 1;
}
html[dir=rtl] .home__carousel:before {
  background: inherit;
  content: "";
  display: block;
  min-height: 200px;
  height: 11vw;
  left: 0;
  position: absolute;
  right: 0;
  z-index: -1;
  -webkit-backface-visibility: hidden;
}
html[dir=rtl] .home__carousel:before {
  top: -10px;
  transform: skewY(5.5deg);
  transform-origin: 100% 0;
}
.home__carousel .carousel-instance {
  padding-top: 50px;
}
.home__carousel .carousel-item {
  width: 100%;
  text-align: center;
  outline: none;
}
.home__carousel .carousel-text {
  font-size: 2.1875rem;
  line-height: 42px;
  width: 750px;
  max-width: 85vw;
  margin: 0 auto;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: bold;
}
@media (max-width: 48em) {
  .home__carousel .carousel-text {
    font-size: 1.5625rem;
    line-height: 35px;
  }
}
.home__carousel.slick-carousel .slick-dots {
  padding: 90px 0;
}
.home__carousel.slick-carousel .slick-navigation-dot {
  background: #4d4d4d;
}
.home__carousel.slick-carousel .slick-active .slick-navigation-dot {
  background: #fff;
}
@media (max-width: 48em) {
  .home__slideshow, .home__overlay {
    display: none;
  }
  .home__slogan {
    position: static;
    color: #fff;
    background: #ffba00;
    padding: 50px 0 100px;
    text-align: center;
    width: 100%;
  }
  .home__slogan .inner {
    margin: 0 auto;
  }
  .home__services .odd,
  .home__services .even {
    padding: 0;
  }
}

.waterbasedpopup .mfp-img {
  max-height: 80vh !important;
}
.service__solutions {
  position: relative;
  z-index: 1;
  margin: 100px 0;
  padding: 20px 0 100px;
  background: #f0f0f0;
}
.service__solutions:after {
  background: inherit;
  content: "";
  display: block;
  min-height: 200px;
  height: 11vw;
  left: 0;
  position: absolute;
  right: 0;
  z-index: -1;
  -webkit-backface-visibility: hidden;
}
.service__solutions:after {
  bottom: 0;
  transform: skewY(-5.5deg);
  transform-origin: 100%;
}
.service__checklist {
  font-size: 1.5625rem;
  line-height: 31px;
}
@media screen and (max-width: 1180px) {
  .service__checklist {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.service__before-after {
  background: #000;
  text-align: center;
  padding: 60px 0 100px;
}
html[dir=ltr] .service__before-after {
  position: relative;
  z-index: 1;
}
html[dir=ltr] .service__before-after:before {
  background: inherit;
  content: "";
  display: block;
  min-height: 200px;
  height: 11vw;
  left: 0;
  position: absolute;
  right: 0;
  z-index: -1;
  -webkit-backface-visibility: hidden;
}
html[dir=ltr] .service__before-after:before {
  top: -10px;
  transform: skewY(-5.5deg);
  transform-origin: 0 0;
}
html[dir=rtl] .service__before-after {
  position: relative;
  z-index: 1;
}
html[dir=rtl] .service__before-after:before {
  background: inherit;
  content: "";
  display: block;
  min-height: 200px;
  height: 11vw;
  left: 0;
  position: absolute;
  right: 0;
  z-index: -1;
  -webkit-backface-visibility: hidden;
}
html[dir=rtl] .service__before-after:before {
  top: -10px;
  transform: skewY(5.5deg);
  transform-origin: 100% 0;
}
.service__before-after h2 {
  display: inline-block;
  padding-bottom: 3px;
  border-bottom: 4px solid #ffba00;
  margin-bottom: 25px;
  color: #fff;
}
.service__before-after .col {
  padding: 0 15px;
}
@media (max-width: 48em) {
  .service__before-after .col {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 1180px) {
  .service__products {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.service__products-list {
  border-top: 1px solid #d5d5d5;
  list-style: none;
  padding: 0;
  margin-top: 150px;
}
.service__products-list li {
  display: block;
  border-bottom: 1px solid #d5d5d5;
}
.service__product {
  max-width: 750px;
  margin: 30px auto;
  display: flex;
  justify-content: space-between;
}
.service .title,
.service .download {
  text-decoration: none;
}
.service .title {
  display: inline-block;
  margin-bottom: 3px;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  position: relative;
  margin-left: 15px;
  color: #000;
}
.service .title:hover {
  border-bottom-color: #000;
}
.service .title::before {
  content: "\f054";
  font-family: FontAwesome;
  font-size: 11px;
  color: #ffba00;
  position: absolute;
  top: 0;
  left: -15px;
}
html[dir=rtl] .service .title {
  margin-left: 0;
  margin-right: 15px;
}
html[dir=rtl] .service .title::before {
  left: auto;
  right: -15px;
  transform: scale(-1, 1);
}
.service .download {
  display: inline-block;
  margin-bottom: 3px;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  position: relative;
  color: #ffba00;
}
.service .download:hover {
  border-bottom-color: #ffba00;
}
@media screen and (max-width: 1180px) {
  .service .download {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.service .download::after {
  content: "\f078";
  font-family: FontAwesome;
  position: absolute;
  top: 0;
  left: 100%;
  margin-left: 4px;
  font-size: 11px;
  color: #ffba00;
}
@media screen and (max-width: 1180px) {
  .service .download::after {
    margin-left: -16px;
  }
}
.service__projects {
  margin-bottom: 100px;
}
.service__projects-slideshow .slideshow-instance .slick-dots {
  padding: 25px 0 0;
}
@media (max-width: 64em) {
  .service {
    overflow: hidden;
  }
}
.header {
  height: 150px;
  background: #fff;
  position: fixed;
  z-index: 999;
  top: 0;
  width: 1400px;
  left: 50%;
  transform: translateX(-50%);
  transition: height 0.3s;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
}
@media (max-width: 64em) {
  .header {
    left: 0;
    transform: none;
  }
}
.header__contents {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1180px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 1180px) {
  .header__contents {
    width: 100%;
    padding-left: 20px;
    padding-right: 40px;
  }
}
html.header-small .header {
  height: 75px;
}
html.header-small .header .header__logo img {
  height: 45px;
}
@media (max-width: 48em) {
  html.header-small .header {
    height: 60px;
  }
  html.header-small .header .header__logo img {
    height: 35px;
  }
}
.header__logo img {
  width: auto;
  height: 59px;
  transition: height 0.3s;
}
.header__nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
}
.header__nav ul,
.header__nav li {
  display: inline-block;
}
.header__nav a {
  margin: 0 35px;
}
.header__nav a, .header__language a {
  display: inline-block;
  position: relative;
  color: #a2a2a2;
  text-decoration: none;
  font-size: 1.0625rem;
  padding-bottom: 3px;
  border-bottom: 3px solid transparent;
  transition: color 0.3s;
}
.header__nav a:hover, .header__language a:hover {
  color: #000;
}
.header__nav a.selected, .header__language a.selected {
  color: #000;
  border-color: #ffba00;
}
.header__nav a.can-expand::after, .header__language a.can-expand::after {
  font-family: FontAwesome;
  content: "\f107";
  font-size: 0.8125rem;
  position: absolute;
  top: 1px;
  right: -14px;
}
html[dir=rtl] .header__nav a.can-expand::after, html[dir=rtl] .header__language a.can-expand::after {
  right: auto;
  left: -14px;
}
.header__language {
  color: #a2a2a2;
}
.header .mobile-menu {
  outline: none;
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
}
html[dir=rtl] .header .mobile-menu {
  right: auto;
  left: 15px;
}
.header .mobile-menu .hamburger-inner, .header .mobile-menu .hamburger-inner::after, .header .mobile-menu .hamburger-inner::before {
  background-color: #ffba00;
}
@media (max-width: 64em) {
  .header {
    height: 100px;
  }
  .header__nav, .header__language {
    display: none;
  }
  .header .mobile-menu {
    display: inline;
  }
}
.contentpage__graytextblock {
  margin: 100px 0 0;
  padding: 0 0 100px;
  background: #f0f0f0;
}
@media screen and (max-width: 1180px) {
  .contentpage__graytextblock {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 1180px) {
  .contentpage h1,
  .contentpage h2,
  .contentpage h3,
  .contentpage p,
  .contentpage ul,
  .contentpage ol,
  .contentpage .wh-video {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.contentpage__threecoltextblock {
  max-width: 1180px;
  margin: 50px auto 100px;
}
.contentpage__threecoltextblock .col-left {
  padding-right: 25px;
}
.contentpage__threecoltextblock .col-middle {
  padding-left: 12.5px;
  padding-right: 12.5px;
}
.contentpage__threecoltextblock .col-right {
  padding-left: 25px;
}
@media (max-width: 64em) {
  .contentpage__threecoltextblock .col-middle {
    padding-left: 25px;
    padding-right: 0;
  }
  .contentpage__threecoltextblock .col-right {
    padding-left: 0;
  }
}
@media (max-width: 48em) {
  .contentpage__threecoltextblock .col-middle {
    padding-left: 0;
  }
}
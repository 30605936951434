html,
body,
p,
ul,
ol,
a,
body .pure-g [class *= "pure-u"],
.wh-rtd p,
.wh-rtd ul,
.wh-rtd ol,
.wh-rtd a {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 22px;
}

h2,
h3,
h4 {
  margin-top: 0;
  color: #ffba00;
  font-weight: 500;
  line-height: 22px;
}

h2 {
  font-size: 25px;
  margin-bottom: 12px;
}

h3 {
  font-size: 20px;
  margin-bottom: 5px;
}

h4 {
  font-size: 16px;
  margin-bottom: 3px;
}

a {
  color: #000;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

p,
ul,
ol {
  margin: 0 0 20px;
}

.wh-rtd-editor p:last-child {
  margin-bottom: 0;
}

ul {
  padding-left: 1.3em;
}

ol {
  padding-left: 1em;
}

.centered {
  text-align: center;
}

.wh-video {
  margin: 60px auto 70px !important;
}

.wh-video .label-before {
  display: block;
  font-size: 22px;
  margin: 0 0 10px;
  font-weight: 500;
}

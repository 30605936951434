@import url("https://fonts.googleapis.com/css?family=Roboto:400,500");
html, body, form {
  padding: 0;
  margin: 0;
}

img {
  border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block;
}

audio, canvas, video {
  display: inline-block;
}

datalist, template {
  display: none;
}

*, *:before, *:after {
  box-sizing: border-box;
}

body {
  -ms-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
}

button {
  border: 0;
  border-radius: 0;
  background: none;
  cursor: pointer;
}

body {
  font-size: 1rem;
  overflow-y: scroll;
}
html.overlay-open body {
  overflow-y: hidden;
}

.push-top {
  height: 150px;
}
@media (max-width: 64em) {
  .push-top {
    height: 100px;
  }
}

::selection {
  background-color: #ffba00;
  color: #000;
}

.sitewrapper {
  max-width: 100vw;
  margin: 0 auto 180px;
}
@media (max-width: 64em) {
  .sitewrapper {
    margin-bottom: 100px;
  }
}

.innerwrapper,
.wh-video {
  margin: 0 auto;
}

.innerwrapper {
  max-width: 1180px;
}
@media screen and (max-width: 1180px) {
  .innerwrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.wh-video {
  max-width: 750px;
}
.wh-video iframe {
  left: 0;
  right: 0;
}
@media screen and (max-width: 1180px) {
  .wh-video iframe {
    width: calc(100% - 2 * 20px) !important;
    left: 20px;
    right: 20px;
  }
}

.parsley-errors-list {
  display: none;
}

hr {
  background-color: #d5d5d5;
  border: 0;
  height: 1px !important;
}

.button {
  color: #a2a2a2;
  font-size: 1rem;
  line-height: 19px;
  padding: 10px 20px;
  border: 1px solid #a2a2a2;
  text-decoration: none;
  transition: all 0.3s;
  display: inline-block;
  outline: none;
}
.button:hover {
  color: #fff;
  background: #000;
  border-color: #000;
}

.pagetitle {
  background: #ffba00;
  color: #fff;
  font-weight: normal;
  margin: 0 0 160px;
}
@media screen and (max-width: 1180px) {
  .pagetitle {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 48em) {
  .pagetitle {
    margin-bottom: 80px;
  }
}
.pagetitle::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100%;
  background: #ffba00;
  height: 200px;
}
html[dir=ltr] .pagetitle {
  position: relative;
  z-index: 1;
}
html[dir=ltr] .pagetitle:after {
  background: inherit;
  content: "";
  display: block;
  min-height: 200px;
  height: 11vw;
  left: 0;
  position: absolute;
  right: 0;
  z-index: -1;
  -webkit-backface-visibility: hidden;
}
html[dir=ltr] .pagetitle:after {
  bottom: 0;
  transform: skewY(-4deg);
  transform-origin: 100%;
}
html[dir=rtl] .pagetitle {
  position: relative;
  z-index: 1;
}
html[dir=rtl] .pagetitle:after {
  background: inherit;
  content: "";
  display: block;
  min-height: 200px;
  height: 11vw;
  left: 0;
  position: absolute;
  right: 0;
  z-index: -1;
  -webkit-backface-visibility: hidden;
}
html[dir=rtl] .pagetitle:after {
  bottom: 0;
  transform: skewY(4deg);
  transform-origin: 0 100%;
}
.pagetitle > span {
  display: block;
  max-width: 1180px;
  margin: 0 auto;
  font-size: 2.8125rem;
  line-height: 45px;
  padding: 60px 0 0;
}
@media (max-width: 48em) {
  .pagetitle > span {
    word-break: break-word;
    padding-bottom: 40px;
  }
}

.subpagetitle {
  text-align: center;
  display: block;
  word-break: break-word;
}
.subpagetitle > span {
  font-size: 2.1875rem;
  line-height: 41px;
  display: inline-block;
  padding-bottom: 3px;
  border-bottom: 4px solid #ffba00;
  font-weight: bold;
  font-family: Georgia, "Times New Roman", Times, serif;
  color: #000;
  margin: 90px 0;
}

.checklist,
.checklist ul {
  list-style: none;
}
.checklist li {
  margin-bottom: 6px;
  position: relative;
}
.checklist li::before {
  position: absolute;
  content: "";
  display: block;
  top: 9px;
  left: -30px;
  background: url(~@mod-blygold/nodejs/img/check.svg) no-repeat 0 0;
  background-size: 22px;
  width: 22px;
  height: 22px;
}
@media (max-width: 48em) {
  .checklist li::before {
    background-size: 16px;
    top: 5px;
    left: -27px;
  }
}

/***********************************************************************

    Forms

***********************************************************************/
.general-form input[type=text],
.general-form input[type=email],
.general-form textarea {
  width: 100%;
  padding: 16px 40px 16px 20px;
  color: #000;
  font-size: 0.9375rem;
  background: #fff;
  border: 1px solid #fff;
  outline: none;
}
.general-form input[type=text]::-webkit-input-placeholder,
.general-form input[type=email]::-webkit-input-placeholder,
.general-form textarea::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color: #a2a2a2;
}
.general-form input[type=text]:-moz-placeholder,
.general-form input[type=email]:-moz-placeholder,
.general-form textarea:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: #a2a2a2;
  opacity: 1;
}
.general-form input[type=text]::-moz-placeholder,
.general-form input[type=email]::-moz-placeholder,
.general-form textarea::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #a2a2a2;
  opacity: 1;
}
.general-form input[type=text]:-ms-input-placeholder,
.general-form input[type=email]:-ms-input-placeholder,
.general-form textarea:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #a2a2a2;
}
html[dir=rtl] .general-form input[type=text],
html[dir=rtl] .general-form input[type=email],
html[dir=rtl] .general-form textarea {
  padding: 16px 20px 16px 40px;
}
@media (max-width: 48em) {
  .general-form input[type=text],
  .general-form input[type=email],
  .general-form textarea {
    min-width: initial;
    width: 100%;
  }
}
.general-form textarea {
  height: 240px;
}
.general-form .input-row {
  padding: 0 0 25px;
}
.general-form input[type=text],
.general-form input[type=email],
.general-form textarea {
  -webkit-appearance: none;
}
.general-form .form-input {
  position: relative;
  display: inline-block;
  width: 370px;
  max-width: 90vw;
}
.general-form .form-input.parsley-success .form-input-indicator {
  display: block;
}
.general-form .form-input.parsley-error input[type=text],
.general-form .form-input.parsley-error input[type=email],
.general-form .form-input.parsley-error textarea {
  border-color: #f00;
}
.general-form .form-input label {
  font-weight: normal;
  display: block;
  margin: 0 0 4px;
  text-align: left;
}
html[dir=rtl] .general-form .form-input label {
  text-align: right;
}
@media (max-width: 48em) {
  .general-form .form-input {
    width: 100%;
  }
}
.general-form .form-input-indicator {
  position: absolute;
  top: 21px;
  right: 10px;
  display: none;
  width: 16px;
  height: 13px;
  background: url(~@mod-blygold/nodejs/img/check.png) no-repeat;
}
html[dir=rtl] .general-form .form-input-indicator {
  right: auto;
  left: 10px;
}
.general-form--big .form-input {
  width: 580px;
}
.general-form--with-labels .form-input-indicator {
  top: 45px;
}

/***********************************************************************

    Full-screen overlays

***********************************************************************/
.fullscreenoverlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.91);
  top: 150px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
}
@media (max-width: 64em) {
  .fullscreenoverlay {
    top: 100px;
  }
}
.fullscreenoverlay.show {
  opacity: 1;
  pointer-events: visible;
}
.fullscreenoverlay .container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
}
.fullscreenoverlay .container .mainlink {
  color: #a2a2a2;
  font-size: 2.1875rem;
  margin: 30px 0;
  line-height: 100%;
  cursor: pointer;
  display: block;
  text-decoration: none;
  transition: color 0.3s;
}
.fullscreenoverlay .container .mainlink:hover, .fullscreenoverlay .container .mainlink.selected {
  color: #fff;
}
.fullscreenoverlay.mobile-menu-overlay .container {
  width: 100vw;
  top: 5vh;
  left: 0;
  transform: none;
  max-height: calc(76vh - 100px);
}
.fullscreenoverlay.mobile-menu-overlay .container.scrollable-container {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.fullscreenoverlay.mobile-menu-overlay .mainlink {
  color: #fff;
  font-size: 1.875rem;
}
.fullscreenoverlay.mobile-menu-overlay .mainlink.is-services > span {
  position: relative;
  display: inline-block;
}
.fullscreenoverlay.mobile-menu-overlay .mainlink.is-services > span::after {
  font-family: FontAwesome;
  content: "\f105";
  font-size: 2rem;
  color: #fff;
  position: absolute;
  top: 2px;
  right: -25px;
}
html[dir=rtl] .fullscreenoverlay.mobile-menu-overlay .mainlink.is-services > span::after {
  right: auto;
  left: -20px;
  transform: rotate(180deg);
}
.fullscreenoverlay.mobile-menu-overlay .mainlink.is-services.enabled > span::after {
  transform: rotate(90deg) !important;
}
.fullscreenoverlay.mobile-menu-overlay .mobile-menu-services {
  display: none;
}
.fullscreenoverlay.mobile-menu-overlay .mobile-menu-services.enabled {
  display: block;
}
.fullscreenoverlay.mobile-menu-overlay .mobile-menu-services .mainlink {
  color: #a2a2a2;
  font-size: 1.25rem;
  margin: 20px 0;
}
.fullscreenoverlay.mobile-menu-overlay .languages {
  position: absolute;
  bottom: 2vh;
  left: 50%;
  transform: translateX(-50%);
  width: 90vw;
  text-align: center;
}
.fullscreenoverlay.mobile-menu-overlay .languages a,
.fullscreenoverlay.mobile-menu-overlay .languages .divider {
  color: #a2a2a2;
  display: inline-block;
  font-size: 1rem;
}
.fullscreenoverlay.mobile-menu-overlay .languages a {
  text-transform: uppercase;
  text-decoration: none;
}
.fullscreenoverlay.mobile-menu-overlay .languages a.selected {
  color: #fff;
}
.fullscreenoverlay.mobile-menu-overlay .languages .divider {
  margin: 0 6px;
}

/***********************************************************************

    Centered content / RTD

***********************************************************************/
.centered-content,
.wh-rtd .normal,
.wh-rtd .centered,
.wh-rtd .ordered,
.wh-rtd .unordered,
.wh-rtd .heading2,
.wh-rtd .heading3,
.wh-rtd .heading4,
.wh-rtd .table {
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
}

.wh-rtd .centered {
  text-align: center;
}

.centered-content {
  margin-top: 15px;
  margin-bottom: 15px;
}

/***********************************************************************

    Slick carousel

***********************************************************************/
.slick-carousel .slick-dots {
  text-align: center;
  margin: 0;
  padding: 90px 0;
  list-style: none;
}
.slick-carousel .slick-dots li {
  display: inline-block;
}
.slick-carousel .slick-navigation-dot {
  width: 10px;
  height: 10px;
  background: #d5d5d5;
  display: inline-block;
  margin: 0 5px;
  border-radius: 100%;
  cursor: pointer;
  transition: background 0.3s;
}
.slick-carousel .slick-active .slick-navigation-dot {
  background: #000;
}
.slick-carousel--photos .slideshow-item {
  width: 1180px;
  height: 820px;
  max-height: 70vh;
  background-repeat: no-repeat;
}

.pure-g {
  /* Modern browsers flexbox */
  display: flex;
  flex-flow: row wrap;
}

.projects-grid {
  max-width: 1178px;
  /* clearfix */
}
.projects-grid::after {
  content: "";
  display: block;
  clear: both;
}
.projects-grid .grid-item {
  position: relative;
  overflow: hidden;
  display: block;
  width: 272px;
  height: 272px;
  float: left;
  margin-bottom: 30px; /* vertical gutter */
}
html.isdesktop .projects-grid .grid-item:hover::after {
  margin-left: 0;
}
html[dir=rtl] .projects-grid .grid-item:hover::after {
  margin-right: 0;
}
html:not(.isdesktop) .projects-grid .grid-item {
  overflow: visible;
  margin-bottom: 30px;
}
.projects-grid .grid-item::after {
  content: "";
  pointer-events: none;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 500px;
  transform: skew(-15deg, 0deg) translateX(-300px);
  margin-left: -100%;
  transition: margin 0.25s;
}
html[dir=rtl] .projects-grid .grid-item::after {
  left: auto;
  right: 0;
  margin-left: 0;
  margin-right: -100%;
  transform: skew(-15deg, 0deg) translateX(300px);
}
html:not(.isdesktop) .projects-grid .grid-item::after {
  display: none;
}
.projects-grid .grid-item img {
  width: 100%;
  height: auto;
}
.projects-grid .grid-item--big {
  width: 574px;
  height: 574px;
  position: relative;
  overflow: hidden;
  display: block;
}
html.isdesktop .projects-grid .grid-item--big:hover::after {
  margin-left: 0;
}
html[dir=rtl] .projects-grid .grid-item--big:hover::after {
  margin-right: 0;
}
html:not(.isdesktop) .projects-grid .grid-item--big {
  overflow: visible;
  margin-bottom: 30px;
}
.projects-grid .grid-item--big::after {
  content: "";
  pointer-events: none;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 575px;
  transform: skew(-15deg, 0deg) translateX(-300px);
  margin-left: -100%;
  transition: margin 0.25s;
}
html[dir=rtl] .projects-grid .grid-item--big::after {
  left: auto;
  right: 0;
  margin-left: 0;
  margin-right: -100%;
  transform: skew(-15deg, 0deg) translateX(300px);
}
html:not(.isdesktop) .projects-grid .grid-item--big::after {
  display: none;
}
@media (max-width: 64em) {
  .projects-grid .grid-item {
    width: 231px;
    height: 231px;
  }
  .projects-grid .grid-item--big {
    width: 487px;
    height: 487px;
  }
}
@media (max-width: 48em) {
  .projects-grid .grid-item, .projects-grid .grid-item--big {
    width: 340px;
    height: 340px;
  }
}
html.isdesktop .projects-grid .grid-item:hover .info {
  opacity: 1;
}
.projects-grid .grid-item .info {
  transition: opacity 0.3s ease;
  opacity: 0;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10;
  max-width: 140px;
}
html[dir=rtl] .projects-grid .grid-item .info {
  left: auto;
  right: 20px;
}
.projects-grid .grid-item--big .info {
  max-width: 250px;
}
.projects-grid .grid-item .branche {
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin: 0 0 20px;
  display: inline-block;
  padding-bottom: 3px;
  border-bottom: 4px solid #ffba00;
}
@media (max-width: 35.5em) {
  .projects-grid .grid-item .branche {
    color: #000;
  }
}
.projects-grid .grid-item .title {
  color: #fff;
}
@media (max-width: 64em) {
  .projects-grid .grid-item .title {
    color: #000;
  }
}
.projects-grid .grid-item .subtitle {
  color: #a2a2a2;
}
@media (max-width: 35.5em) {
  .projects-grid .grid-item {
    position: static !important;
    width: 48%;
    margin-right: 2%;
    height: auto;
  }
  .projects-grid .grid-item .info, .projects-grid .grid-item--big .info {
    position: static;
    opacity: 1;
    max-width: initial;
  }
}
@media (max-width: 35.5em) {
  .projects-grid .grid-item {
    width: 100%;
    margin-right: 0;
  }
}
.projects-grid--no-hover .grid-item {
  position: relative;
  overflow: hidden;
  display: block;
}
html.isdesktop .projects-grid--no-hover .grid-item:hover::after {
  margin-left: 0;
}
.projects-grid--no-hover .grid-item::after {
  margin-left: 0 !important;
}
html[dir=rtl] .projects-grid--no-hover .grid-item:hover::after {
  margin-right: 0;
}
html[dir=rtl] .projects-grid--no-hover .grid-item::after {
  margin-right: 0 !important;
}
html:not(.isdesktop) .projects-grid--no-hover .grid-item {
  overflow: visible;
  margin-bottom: 30px;
}
.projects-grid--no-hover .grid-item::after {
  content: "";
  pointer-events: none;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 500px;
  transform: skew(-15deg, 0deg) translateX(-300px);
  margin-left: -100%;
  transition: margin 0.25s;
}
html[dir=rtl] .projects-grid--no-hover .grid-item::after {
  left: auto;
  right: 0;
  margin-left: 0;
  margin-right: -100%;
  transform: skew(-15deg, 0deg) translateX(300px);
}
html:not(.isdesktop) .projects-grid--no-hover .grid-item::after {
  display: none;
}
.projects-grid--no-hover .info {
  opacity: 1 !important;
}
@media (max-width: 64em) {
  .projects-grid {
    max-width: 984px;
  }
}
@media (max-width: 48em) {
  .projects-grid {
    max-width: 700px;
  }
}
@media (max-width: 35.5em) {
  .projects-grid {
    height: auto !important;
  }
}
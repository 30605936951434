.events {
  margin-bottom: 100px;
}
.events__items {
  margin: 40px 0 0;
}
.events__header-row-item {
  color: #d5d5d5;
  margin: 0 0 10px;
}
.events__title {
  display: inline-block;
  margin-bottom: 3px;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  position: relative;
  margin-left: 15px;
  font-weight: bold;
  text-decoration: none;
}
.events__title:hover {
  border-bottom-color: #000;
}
.events__title::before {
  content: "\f054";
  font-family: FontAwesome;
  font-size: 12px;
  color: #ffba00;
  position: absolute;
  top: 0;
  left: -15px;
}
html[dir=rtl] .events__title {
  margin-left: 0;
  margin-right: 15px;
}
html[dir=rtl] .events__title::before {
  left: auto;
  right: -15px;
  transform: scale(-1, 1);
}
.events__date {
  max-width: 200px;
}
.events__item-container {
  position: relative;
  padding: 20px 0 40px;
}
.events__item-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  height: 1px;
  background: #d5d5d5;
}
html[dir=rtl] .events__item-container::before {
  left: auto;
  right: 0;
}
.events__logo {
  padding-right: 15px;
}
@media (max-width: 48em) {
  .events__header-row {
    display: none !important;
  }
  .events__item-container {
    padding-bottom: 0;
  }
  .events__item-container::before {
    width: 100%;
  }
  .events__item-container--title {
    padding-top: 16px;
  }
  .events__item-container--location, .events__item-container--date {
    padding-top: 0;
    padding-left: 15px;
  }
  .events__item-container--date {
    order: 1;
  }
  .events__item-container--location {
    order: 2;
    margin: 0 0 20px;
  }
  .events__item-container--hide-mobile::before {
    display: none;
  }
}
.branches__body {
  margin-bottom: 80px;
}
.branches__grid {
  margin-bottom: 60px;
}
.branches__branches {
  margin: 0 0 60px;
  padding: 0 100px;
  text-align: center;
}
.branches__branches a {
  font-size: 20px;
  text-decoration: none;
  color: #a2a2a2;
  margin: 0 25px 25px 25px;
  transition: color 0.3s;
  display: inline-block;
  padding-bottom: 3px;
  border-bottom: 4px solid #fff;
}
.branches__branches a.selected, .branches__branches a:hover {
  color: #000;
}
.branches__branches a.selected {
  border-color: #ffba00;
}
@media (max-width: 35.5em) {
  .branches__branches {
    display: none;
  }
}
.branches--details__backlink-top {
  padding: 40px 0 15px;
}
.branches--details__backlink {
  display: inline-block;
  margin-bottom: 3px;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  position: relative;
  margin-left: 15px;
}
.branches--details__backlink:hover {
  border-bottom-color: #000;
}
.branches--details__backlink::before {
  content: "\f054";
  font-family: FontAwesome;
  font-size: 14px;
  color: #ffba00;
  position: absolute;
  top: -1px;
  left: -15px;
}
html[dir=rtl] .branches--details__backlink {
  margin-left: 0;
  margin-right: 15px;
}
html[dir=rtl] .branches--details__backlink::before {
  left: auto;
  right: -15px;
  transform: scale(-1, 1);
}
.branches--details__backlink::before {
  transform: rotate(180deg);
  font-size: 11px;
}
.newsletter {
  background: #d5d5d5;
  padding: 60px 0;
  text-align: center;
}
.newsletter--sent .newsletter__form-sent {
  display: block;
}
.newsletter--sent .newsletter__form {
  display: none;
}
.newsletter__title {
  font-size: 1.25rem;
  line-height: 25px;
  margin-bottom: 10px;
}
.newsletter__form {
  margin: 30px 0 0;
}
.newsletter .col-left,
.newsletter .col-right {
  padding: 10px;
}
.newsletter .col-left {
  text-align: right;
}
html[dir=rtl] .newsletter .col-left {
  text-align: left;
}
.newsletter .col-right {
  text-align: left;
}
html[dir=rtl] .newsletter .col-right {
  text-align: right;
}
.newsletter__submit {
  margin: 20px 0 0;
}
.newsletter__form-sent {
  display: none;
}
.footer {
  background: #ffba00;
  padding: 160px 0 50px;
}
html[dir=ltr] .footer {
  position: relative;
  z-index: 1;
}
html[dir=ltr] .footer:after {
  background: inherit;
  content: "";
  display: block;
  min-height: 200px;
  height: 11vw;
  left: 0;
  position: absolute;
  right: 0;
  z-index: -1;
  -webkit-backface-visibility: hidden;
}
html[dir=ltr] .footer:after {
  bottom: 0;
  transform: skewY(-5.5deg);
  transform-origin: 100%;
}
html[dir=rtl] .footer {
  position: relative;
  z-index: 1;
}
html[dir=rtl] .footer:after {
  background: inherit;
  content: "";
  display: block;
  min-height: 200px;
  height: 11vw;
  left: 0;
  position: absolute;
  right: 0;
  z-index: -1;
  -webkit-backface-visibility: hidden;
}
html[dir=rtl] .footer:after {
  bottom: 0;
  transform: skewY(5.5deg);
  transform-origin: 0 100%;
}
.footer__address-title {
  display: inline-block;
  padding-bottom: 3px;
  border-bottom: 4px solid #fff;
  font-weight: 500;
}
.footer .col-right {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.footer__social {
  color: #fff;
  text-decoration: none;
  border: 2px solid #fff;
  border-radius: 50%;
  margin: 0 6px;
  transition: background 0.3s, color 0.3s;
  width: 44px;
  height: 44px;
  text-align: center;
}
.footer__social.fa {
  font-size: 22px;
  line-height: 44px;
}
.footer__social.fa-youtube {
  line-height: 41px;
  padding: 0 0 2px;
}
.footer__social.fa-twitter {
  line-height: 42px;
}
.footer__social.fa-linkedin {
  line-height: 38px;
}
.footer__social:hover {
  background: #fff;
  color: #ffba00;
}
.footer__contact-link {
  display: inline-block;
  margin-bottom: 3px;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  position: relative;
  margin-left: 15px;
}
.footer__contact-link:hover {
  border-bottom-color: #000;
}
.footer__contact-link::before {
  content: "\f054";
  font-family: FontAwesome;
  font-size: 14px;
  color: #fff;
  position: absolute;
  top: -1px;
  left: -15px;
}
html[dir=rtl] .footer__contact-link {
  margin-left: 0;
  margin-right: 15px;
}
html[dir=rtl] .footer__contact-link::before {
  left: auto;
  right: -15px;
  transform: scale(-1, 1);
}
.footer__disclaimer {
  margin: 60px 0 0;
  color: #fff;
}
@media (max-width: 48em) {
  .footer {
    padding-top: 50px;
  }
  .footer .col-left {
    margin-bottom: 30px;
  }
  .footer .col-middle {
    margin-bottom: 35px;
  }
  .footer .col-right {
    justify-content: flex-start;
  }
  .footer .col-right .footer__social:first-child {
    margin-left: 0;
  }
}
.contact__map-container {
  position: relative;
}
.contact__map-container #addresses {
  min-height: 447px;
}
@media screen and (max-width: 1180px) {
  .contact__body {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.contact__continents {
  list-style: none;
}
.contact__pagetitle h2 {
  color: #000;
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 5px;
}
.contact__addresses {
  padding: 0;
  margin-top: 85px;
  margin-bottom: 0px;
  list-style: none;
  min-height: 450px;
}
.contact__addresses a {
  text-decoration: none;
  color: #a2a2a2;
  transition: color 0.3s;
}
.contact__addresses a:hover, .contact__addresses a.selected {
  color: #000;
}
.contact__addresses h1 {
  font-size: 1.75rem;
}
.contact__locations-container {
  margin-top: 50px;
  display: none;
}
.contact__locations-container.enabled {
  display: block;
}
.contact__locations-title, .contact__location {
  padding: 15px 0 0;
  position: relative;
}
.contact__locations-title::before, .contact__location::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  height: 1px;
  background: #d5d5d5;
}
html[dir=rtl] .contact__locations-title::before, html[dir=rtl] .contact__location::before {
  left: auto;
  right: 0;
}
.contact__location {
  margin: 0 0 30px;
}
.contact__address {
  line-height: 25px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.contact__address--title {
  font-weight: bold;
}
.contact__address--link {
  text-decoration: none;
}
.contact__address--link:hover {
  text-decoration: underline;
}
.contact__mobile-location {
  display: none;
}
@media (max-width: 64em) {
  .contact__pagetitle,
  .contact #map-continents {
    display: none;
  }
  .contact__countrylinks {
    display: none;
  }
  .contact__addresses {
    height: auto !important;
    max-height: initial;
    padding-top: 10px;
  }
  .contact__addresses li {
    display: block !important;
    margin: 0 0 50px;
  }
  .contact__addresses li.enabled h1 {
    margin-bottom: 40px;
  }
  .contact__addresses li.enabled h1:after {
    transform: rotate(90deg);
  }
  .contact__addresses li.enabled .contact__countrylinks {
    display: block;
  }
  .contact__addresses h1 {
    cursor: pointer;
    margin-bottom: 25px;
    padding-bottom: 15px;
    border-bottom: 1px solid #000;
    position: relative;
    font-size: 2.125rem;
  }
  .contact__addresses h1::after {
    font-family: FontAwesome;
    content: "\f105";
    position: absolute;
    font-size: 22px;
    right: 0;
    top: 3px;
  }
  html[dir=rtl] .contact__addresses h1::after {
    display: none;
  }
  .contact__countrylink-container {
    margin: 40px 0;
  }
  .contact__countrylink-container.enabled .contact__countrylink::after {
    transform: rotate(90deg);
  }
  .contact__countrylink-container.enabled .contact__mobile-location {
    display: block;
  }
  .contact__countrylink {
    font-size: 1.8125rem;
    font-weight: bold;
    display: block;
    position: relative;
    border-bottom: 1px dashed #a2a2a2;
    padding-bottom: 15px;
  }
  .contact__countrylink:hover {
    color: #a2a2a2 !important;
  }
  .contact__countrylink::after {
    font-family: FontAwesome;
    content: "\f105";
    position: absolute;
    font-size: 22px;
    right: 0;
    top: 3px;
  }
  html[dir=rtl] .contact__countrylink::after {
    display: none;
  }
  .contact__mobile-location {
    border-bottom: 1px solid #d5d5d5;
    background: #efefef;
    padding: 20px;
  }
  .contact__mobile-location, .contact__mobile-location > div, .contact__mobile-location a {
    color: #000;
    font-size: 1rem;
    line-height: 22px;
  }
  .contact__mobile-location > div {
    margin: 5px 0;
  }
  .contact__mobile-location a {
    text-decoration: underline;
  }
}
.contact__form-container {
  margin-top: 85px;
  background: #f0f0f0;
  padding-bottom: 100px;
}
.contact__form-container--sent .contact__form {
  display: none;
}
.contact__form-container--sent .contact__form-sent {
  display: block;
}
.contact__form {
  text-align: center;
}
.contact__form-submit {
  text-align: left;
}
html[dir=rtl] .contact__form-submit {
  text-align: right;
}
.contact__form-sent {
  display: none;
  text-align: center;
}

#map-continents {
  transform: scale(0.9);
  position: absolute;
  top: 0;
  left: 70px;
}
#map-continents,
#map-continents * {
  box-sizing: initial;
}

.css-map-container {
  margin: 0 !important;
}

.page-contact .newsletter {
  margin-top: 0;
}

/* default tooltip */
.css-map > li a, .css-map > li a:hover, #map-tooltip, .cssmap-tooltip-content {
  background: #111;
  background: rgba(0, 0, 0, 0.8);
  color: #eee;
  font: normal 12px Roboto, sans-serif;
  padding: 0.5em 1.2em;
  text-align: center;
  text-shadow: 0 1px 0 #000;
  white-space: nowrap;
}

.m1450 .css-map > li a, .m1280 .css-map > li a {
  font-size: 14px;
}

.m650 .css-map > li a, .m540 .css-map > li a {
  font-size: 11px;
}

.m430 .css-map > li a, .m320 .css-map > li a {
  font-size: 10px;
}

.m250 .css-map > li a, .m210 .css-map > li a {
  font-size: 9px !important;
}

/* tooltip arrow; when you change its size don't forget to set tooltipArrowHeight variable in the cssMap(); function */
.css-map > li a .tooltip-arrow {
  bottom: -5px; /* must be the same as a border-width */
  border: solid;
  border-color: #111 transparent;
  border-color: rgba(0, 0, 0, 0.8) transparent;
  border-width: 5px 5px 0 5px; /* this's a height of the arrow */
  margin-left: -5px; /* must be the same as a border-width */
}

.css-map > li a.tooltip-right .tooltip-arrow {
  margin-right: -5px; /* must be the same as a tooltip-arrow border-width */
}

.css-map > li a.tooltip-top .tooltip-arrow { /* set tooltip under the arrow */
  border-width: 0 5px 5px 5px;
  top: -5px;
}

/* tooltip style when the region is activated */
.css-map .active-region a {
  background-color: #111;
}

.css-map .active-region a .tooltip-arrow {
  border-color: #111 transparent;
}

/* links in the visible list of regions */
.map-visible-list a {
  /* color: #eee; */
}

.map-visible-list a:hover, .map-visible-list a:focus, .map-visible-list li.focus a {
  /* color: #fc0; */
}

.map-visible-list a:active, .map-visible-list li.active-region a {
  /* color: #cc3; */
}

/* default pre-loader */
.map-loader {
  background: #111;
  background: rgba(0, 0, 0, 0.8);
  color: #eee;
  font: normal 14px "Lucida Grande", Arial, sans-serif;
  padding: 0.6em 1.2em;
  text-shadow: 0 1px 0 #000;
  -moz-border-radius: 0.6em;
  -ms-border-radius: 0.6em;
  -webkit-border-radius: 0.6em;
  border-radius: 0.6em;
}

.m1450 .map-loader, .m1280 .map-loader {
  font-size: 16px;
}

.m540 .map-loader {
  font-size: 12px;
}

.m430 .map-loader, .m320 .map-loader {
  font-size: 11px;
}

.m250 .map-loader, .m210 .map-loader {
  font-size: 10px;
}

/* link to the search engine - shown in the multipleClick mode */
.map-search-link {
  /* your properties goes here */
}

.map-search-link:hover, a.map-search-link:focus {
  /* selected link style */
}

.m430 .map-search-link, .m320 .map-search-link {
  /* style for a specific map size, ex.:
  font-size: 1em;
  */
}